<template>
  <div class="goodNews">
    <Head classA="3" headBg="1"></Head>
    <div class="main">
      <h2>用户心声第十期│诊所大变样，形象提升就是这么简单</h2>
      <!-- <p class="time">2019-11-9</p> -->

      <p>究竟云诊室能给诊所带来哪些变化？让我们一起来看看吧。</p>

      <p>
        自面市至今，致医云诊室4年间服务了上万家诊所。看着客户数量的逐日扩增，直至占领整个中国版图，我们心中是无限欣喜的。
      </p>

      <p>
        我们希望的不仅仅是为诊所提供一套系统，或者一台设备，而是为老师们创建一整套高效省心的工作流程，一整套诊所信息化解决方案，提供一个切实提升诊所业务水平的利器。
      </p>

      <p>
        云南石林恕臣诊所的王恕臣老师，便真正认识到云诊室的价值并加以利用，让自己的诊所面貌焕然一新，不仅自身的工作效率大福提升，也得到越来越多的患者的信赖。下面就跟着小编的镜头，一起走进他的诊所吧。
      </p>

      <h4>1、患者就诊流程有哪些改变？</h4>
      <p>
        我的一体机、云屏、挂号机，还有处方打印机，四个是连在一起的。我看完了一点，处方在护士那边就出来了，患者不用自己拿着处方跑，只要等着就可以了，这个功能是特别好的。
      </p>

      <p>
        来我这里看病的大部分都是一些老顾客，来了自己取号，取了号自己就到候诊椅上去坐着了，不需要在这个地方等候了。
      </p>

      <p>
        我就从这个地方叫号他就知道了，叫到号我看完以后，他也不用拿处方、不用催护士，也是到候诊椅上坐着等待治疗就可以了，非常便捷。
      </p>

      <p>
        以前要拿个处方到处跑着找护士，现在也不用。省去很多以前什么态度不好之类的问题，所以大家沟通交流也非常方便。
      </p>

      <h4>2、患者现在如何评价诊所？</h4>
      <p>
        电子处方一出来提高诊所形象，患者看了也舒服，“你看他这个地方电子化管理”，人家也相信这些东西，肯定对自己的形象也好一点。
      </p>

      <p>患者大部分反映来这看病，方便快捷。</p>

      <p>
        以前没有叫号的时候，他挂了号以后，每天早上排队排两路，甚至排到外面去了，大家都不方便。
      </p>

      <p>
        有了叫号以后，他就不会担心过号错过了。挂了号以后就坐着等着就可以了，还可以弄一下小孩子，小孩子也不会大哭大闹的，这个系统确实非常好。
      </p>

      <h4>3、我的工作发生了哪些变化？</h4>
      <p>
        像我看病经常用的这些中药西药，我给它做成处方模板，这样看起来非常方便便捷，也可以帮助看病效果更好。
      </p>

      <p>
        以前开个处方还需要挺长时间的，因为每一个处方是记在脑子里面，病人不同症状的话，你要重新考虑。但是我做成模板的话，思路比以前更好，效果也提高了
        。
      </p>

      <p>
        药品管理非常好，不用经常盘点。库存管理更方便，以前要清仓，你都不知道哪些药品经常过期，现在有近效期提醒。药品入库多少、库存多少，一目了然，省了很多的人工，非常方便。
      </p>
    </div>
    <Foot></Foot>
  </div>
</template>
<style scoped>
@import '../../assets/style/goodNews.css';
</style>
<script>
import Foot from '@/components/common/Foot'
import Head from '@/components/common/Head'

export default {
  name: 'news',
  components: {
    Head,
    Foot
  },
  data () {
    return {}
  },
  methods: {},
  mounted () {}
}
</script>
